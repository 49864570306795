@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #9f1239;
}

.font-poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.font-poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.font-poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.font-poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

.font-poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: timer 1.5s linear infinite;
  animation: timer 1.5s linear infinite;
}

@-webkit-keyframes timer {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes timer {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.base-timer {
  position: relative;
  width: 50px;
  height: 50px;
}

.base-timer__svg {
  transform: scaleX(1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 15px;
  stroke: #9ca3af;
}

.base-timer__path-remaining {
  stroke-width: 15px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 10px;
}
